import React from 'react'

import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown'

import V1DropdownButton from './themes/v1/DropdownButton'
import V2DropdownButton from './themes/v2/DropdownButton'

import V1DropdownButtonLink from './themes/v1/DropdownButtonLink'
import V2DropdownButtonLink from './themes/v2/DropdownButtonLink'

import { mediaDownloadUrl } from '../utilities/shouldShowDownloadDropdown'

export default class DownloadDropdown extends React.Component {

  themedDropdownButtonComponent() {
    switch (this.props.theme) {
      case 1:
        return V1DropdownButton
      case 2:
        return V2DropdownButton
    }
  }

  themedDropdownButtonLinkComponent() {
    switch (this.props.theme) {
      case 1:
        return V1DropdownButtonLink
      case 2:
        return V2DropdownButtonLink
    }
  }

  render() {
    const downloadingDisabled = this.props.settings.downloading_disabled
    const DropdownButton = this.themedDropdownButtonComponent()
    const downloadUrl = mediaDownloadUrl(downloadingDisabled, this.props.media)

    // If there are any attachments, combine the download link and list of
    // attachments in a dropdown.
    if (this.props.attachments.length > 0) {
      return (
        <Dropdown>
          <DropdownTrigger className="resources"><DropdownButton>Resources</DropdownButton></DropdownTrigger>
          <DropdownContent>
            <ul className="media-attachment-popup resources-popup">
              {this.props.attachments.map((a, index) => (
                <li className="attachment-url" key={index}>
                  <a target="_blank" href={a.url}>{a.title || a.filename}</a>
                </li>
              ))}
              {downloadUrl &&
                <li className="download-url">
                  <a target="_blank" href={downloadUrl}>Download This Media</a>
                </li>
              }
            </ul>
          </DropdownContent>
        </Dropdown>
      )
    }
    // As a shortcut, if there were no attachments but at least a download link,
    // provide a button-like link directly to the file.
    if (downloadUrl) {
      const DropdownButtonLink = this.themedDropdownButtonLinkComponent()
      return (
        <DropdownButtonLink role="button" target="_blank" href={downloadUrl}>Download</DropdownButtonLink>
      )
    } else {
      return <></>
    }
  }
}
