import React from 'react'

import EmbeddedPlyr from './EmbeddedPlyr'
import ThirdPartyEmbeddedPlyr from './ThirdPartyEmbeddedPlyr'

import axios from 'axios'
import { uniqueId } from 'lodash'

import audioOnlyPlayer from '../utilities/audioOnlyPlayer'
import firstSource from '../utilities/firstSource'

export default class MediaPlayer extends React.Component {

  constructor(props) {
    super(props)

    // Uniquely identify this player to fix a react-plyr bug:
    // https://github.com/xDae/react-plyr/issues/29
    //
    // See this ID’s usage in the component’s className, below.
    this.id = uniqueId()

    // Use a ref to access the react-plyr API so we can monitor the internal
    // video progress state for analytics.
    this.playerRef = React.createRef()

    this.onPlay = this.onPlay.bind(this)
    this.onPause = this.onPause.bind(this)
  }

  componentDidMount() {
    this.resetPlayer()
  }

  componentDidUpdate(previousProps) {
    if(previousProps !== this.props) {
      this.resetPlayer()
    }
  }

  renderVimeo() {
    return this.renderThirdParty('vimeo')
  }

  renderYoutube() {
    return this.renderThirdParty('youtube')
  }

  renderThirdParty(provider) {
    const uniqueId = `react-plyr-${this.id}-third-party-${this.props.media.id}`
    return (
      <ThirdPartyEmbeddedPlyr
        ref={this.playerRef}
        id={uniqueId}
        embedId={this.props.media.third_party_id}
        provider={provider}
        onPlay={this.onPlay}
        onPause={this.onPause}
      />
    )
  }

  renderRegularPlayer() {
    const url = firstSource(this.props.contentType, this.props.sources).url
    const type = audioOnlyPlayer(url, this.props.settings && this.props.settings.show_audio_thumbnails) ? 'audio' : 'video'
    const uniqueId = `react-plyr-${this.id}-${type}-${this.props.media.id}`

    return(
      <EmbeddedPlyr
        ref={this.playerRef}
        id={uniqueId}
        type={type}
        url={url}
        preload="none"
        poster={this.props.media.show_large_thumbnail ? this.props.media.thumbnails.large : this.props.media.thumbnails.original}
        onPlay={this.onPlay}
        onPause={this.onPause}
      />
    )
  }

  renderLoading() {
    return(
      <div className="media-box">
        <div className="media-box-aspect-helper"></div>
        <div className="media-box-content-container align-middle">
          <div className="media-box-content">
            <div className="media-box-icon media-spinner"></div>
            <div className="media-box-header">LOADING PLAYER…</div>
          </div>
        </div>
      </div>
    )
  }

  renderError() {
    return(
      <div className="media-box">
        <div className="media-box-aspect-helper"></div>
        <div className="media-box-content-container align-middle">
          <div className="media-box-content first-upload-box">
            <div className="media-box-icon media-error-icon"></div>
            <div className="media-box-header">THERE WAS AN ERROR!</div>
            <div className="media-box-body">Something went wrong and we weren’t able to process your media. Please try to upload your media again.</div>
          </div>
        </div>
      </div>
    )
  }

  renderIsReady() {
    return (
      <div className="media-player-video-container">
        {this.props.media.upload_type === 'youtube' && this.renderYoutube()}
        {this.props.media.upload_type === 'vimeo' && this.renderVimeo()}
        {this.props.media.upload_type === 'clover' && this.renderRegularPlayer()}
      </div>
    )
  }

  renderProcessing() {
    return(
      <div className="media-box">
        <div className="media-box-aspect-helper"></div>
        <div className="media-box-content-container align-middle">
          <div className="media-box-content first-upload-box">
            <div className="media-box-icon media-spinner"></div>
            <div className="media-box-header">PROCESSING MEDIA…</div>
            <div className="media-box-body">While your file is processing, feel free to add any info you’d like to this item, or continue editing the rest of your site.</div>
          </div>
        </div>
      </div>
    )
  }

  triggerPlayed() {
    axios.post(`//mediaplayer.${this.props.baseDomain}/analytics/media/${this.props.media.id}/played`)
  }

  onPlay() {
    const currentPlayer = this.playerRef.current
    const player = currentPlayer.player

    this.setState({ isPlayed: true })

    // If the video has been restarted at 0 and was already hasBeenPlayed, reset the
    // hasBeenPlayed flag to allow multiple plays
    if (this.hasBeenPlayed && player.getCurrentTime() === 0) {
      this.resetPlayer()
    }

    // If the video hasn't completed a play (and no timer is running), set up a
    // timer to track the play time.
    if (!this.timer && !this.hasBeenPlayed) {
      this.resetPlayer()
      this.neededPlaytime = this.calculateNeededPlaytime(player.duration)

      // Round everything to 500ms. Every time this interval ticks, add .5s to
      // the total counted play time. Once we hit the needed play time for a
      // play, trigger a 'hasBeenPlayed' event.
      this.timer = setInterval(() => {
        this.playtime += 0.5
        if (player.currentTime >= this.neededPlaytime) {
          this.hasBeenPlayed = true
          this.triggerPlayed()
          this.resetPlayer()
        }
      }, 500)
    }
  }

  onPause() {
    if (this.timer) {
      this.resetPlayer(this.timer)
    }
  }

  resetPlayer() {
    clearInterval(this.timer)
    this.timer = null
    this.playtime = 0
    this.neededPlaytime = 0
    this.hasBeenPlayed = false
  }

  calculateNeededPlaytime(duration) {
    return (duration * 0.1)
  }

  render() {
    const { isLoading, hasError, isReady, isProcessing } = this.props
    return (
      <div key={`${this.props.media.id}-${this.props.media.state}`} className="media-player-box">
        { isLoading && this.renderLoading() }
        { hasError && this.renderError() }
        { isReady && this.renderIsReady() }
        { isProcessing && this.renderProcessing() }
      </div>
    )
  }
}
