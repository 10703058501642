import { times } from 'lodash'

import React from 'react'
const { Fragment } = React

import renderHTML from 'react-render-html'

import PlaceholderButton from '../../PlaceholderButton'
import DownloadDropdown from '../../DownloadDropdown'
import ShareButton from '../../ShareButton'

import classnames from 'classnames'

import shouldShowDownloadDropdown from '../../../utilities/shouldShowDownloadDropdown'

export default class MediaBody extends React.Component {

  componentDidUpdate() {
    if (this.props.componentDidUpdate) {
      return this.props.componentDidUpdate.apply(this, arguments)
    }
  }

  renderInfo() {
    const { date, series, speaker } = this.props.media
    const info = []
    let index = 0
    if(date) {
      info.push(
        <div className="text-content media-date" key={index++}>
          <label>Date</label> { moment(date).format('MMMM D, YYYY') }
        </div>
      )
    }
    if(series) {
      info.push(
        <div className="text-content media-series" key={index++}>
          <label>Series</label> { series }
        </div>
      )
    }
    if(speaker) {
      info.push(
        <div className="text-content media-speaker" key={index++}>
          <label>Speaker</label> { speaker }
        </div>
      )
    }
    while (info.length < 3) {
      // Pad the container with the remaining lines to keep the height constant.
      info.push(
        <div className="text-content" key={index++}>&nbsp;</div>
      )
    }
    return info
  }

  render() {
    const downloadingDisabled  = this.props.settings.downloading_disabled
    const showDownloadDropdown = shouldShowDownloadDropdown(downloadingDisabled, this.props.media, this.props.attachments)
    const footerClass = classnames('media-footer', {
      'two-button': showDownloadDropdown,
      'one-button': !showDownloadDropdown
    })

    return(
      <div>
        <div className="media-contents">
          <header className="text-content media-video-title">{this.props.media.title}</header>
          <div className="text-content media-description" ref={this.props.mediaDescriptionRef}>
            <div className="media-description-full-size" ref={this.props.mediaDescriptionFullSizeRef}>
              { renderHTML(this.props.media.description || '')}
            </div>
          </div>

          { this.props.shouldShowMore() && this.props.renderShowMore() }
        </div>
        <div className="media-meta-data">
          <div className="media-info">
            { this.renderInfo() }
          </div>

          <footer className={footerClass}>
            { this.props.isLoading ?
              times(2, (i) => <PlaceholderButton {...this.props} key={i} />) :
              <Fragment>
                { showDownloadDropdown && <DownloadDropdown {...this.props} /> }
                <ShareButton {...this.props} />
              </Fragment>
            }
          </footer>
        </div>
     </div>
    )
  }
}
