export default class MediaRegistry {

  constructor() {
    this.widgets = []
  }

  add(widget) {
    this.widgets.push(widget)
  }

  remove(widgetId) {
    this.widgets = this.widgets.filter(w => w.props.id === widgetId )
  }

  reset() {
    this.widgets = []
  }

  update(widgetId) {
    let updateWidget = this.widgets.find(w => w.props.id === widgetId )
    if(updateWidget) { updateWidget.loadData() }
  }

  updateAll() {
    this.widgets.forEach(w => w.loadData())
  }
}
