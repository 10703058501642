import React, { Component } from 'react'
import Plyr from 'plyr'

const iframeContainerStyle = {
  position: 'relative',
  overflow: 'hidden',
  paddingTop: '56.25%'
}

const iframeStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%'
}


// This component is a little tricky because the plyr embeds do not work
// with greenhouse (all the iframes, etc). So we have to use the iframe
// embeds for youtube and vimeo. We want to keep the plyr events on the
// customer sites so we have to use plyr. It works on customer sites tho.

export default class ThirdPartyEmbeddedPlyr extends Component {
  render() {
    if (this.props.provider === 'youtube') {
      return (
        <div style={iframeContainerStyle}>
          <iframe
            style={iframeStyle}
            src={`https://www.youtube.com/embed/${this.props.embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={false}
          />
        </div>
      )
    } else if (this.props.provider === 'vimeo') {
      return (
        <div style={iframeContainerStyle}>
          <iframe
            style={iframeStyle}
            src={`https://player.vimeo.com/video/${this.props.embedId}`}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen={false}
          />
        </div>
      )
    } else {
      return null
    }
  }
}
