import React, { Component } from 'react'
import Plyr from 'plyr'


export default class EmbeddedPlyr extends Component {

  renderVideo() {
    return (
      <video
        id={this.props.id}
        data-poster={this.props.poster}
      >
        <source src={this.props.url} />
      </video>
    )
  }

  renderAudio() {
    return (
      <audio
        id={this.props.id}
        data-poster={this.props.poster}
      >
        <source src={this.props.url} />
      </audio>
    )
  }

  render() {
    return this.props.type === 'video' ?
      this.renderVideo() : this.renderAudio()
  }

  componentDidMount() {
    this.player = new Plyr(`#${this.props.id}`)
    this.player.on('play', this.props.onPlay)
    this.player.on('pause', this.props.onPause)
  }

  componentWillUnmount() {
    this.player.destroy()
  }
}
