import React from 'react'

import V1DropdownButton from './themes/v1/DropdownButton'
import V2DropdownButton from './themes/v2/DropdownButton'

export default class ShareButton extends React.Component {

  themedDropdownButtonComponent() {
    switch (this.props.theme) {
      case 1:
        return V1DropdownButton
      case 2:
        return V2DropdownButton
    }
  }

  render() {
    const DropdownButton = this.themedDropdownButtonComponent()

    return (
      <DropdownButton>&nbsp;</DropdownButton>
    )
  }
}
