import React from 'react'
const { Fragment } = React

export default class NoMediaBody extends React.Component {

  render() {

    return(
      <Fragment>
        <div className="media-player-box">
          <div className="media-box">
            <div className="media-box-aspect-helper"></div>
            <div className="media-box-content-container align-middle">
              <div className="media-box-content first-upload-box">
                <div className="media-box-icon media-upload-icon"></div>
                <div className="media-box-header">GET STARTED!</div>
                <div className="media-box-body">Simply upload a video or audio file from your computer.</div>
                <div className="media-box-button"><button className="media-button add-media"><span className="icon-add"></span> Add Media</button></div>
              </div>
            </div>
          </div>
        </div>
        <div className="media-player-body">
          <div>
            <div className="media-header">
              <header className="text-content media-video-title">Sample Title</header>
              <div className="media-info">
                <div className="text-content media-speaker">Speaker</div>
                <div className="text-content media-info-divider">|</div>
                <div className="text-content media-series">Series</div>
              </div>
              <div className="media-details">
                <div className="text-content media-date">Date</div>
              </div>
            </div>
            <div className="media-description text-content">
              <div className="media-description-full-size"><p>Curabitur blandit tempus porttitor. Vestibulum id ligula porta felis euismod semper. Cras mattis consectetur purus sit amet fermentum. Maecenas faucibus mollis interdum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Aenean lacinia bibendum nulla sed consectetur. Maecenas faucibus mollis interdum. Curabitur blandit tempus porttitor.</p></div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
