import React from 'react'

import renderHTML from 'react-render-html'

import PlaceholderButton from '../../PlaceholderButton'
import DownloadDropdown from '../../DownloadDropdown'
import ShareButton from '../../ShareButton'

import shouldShowDownloadDropdown from '../../../utilities/shouldShowDownloadDropdown'

export default class MediaBody extends React.Component {

  componentDidUpdate() {
    if (this.props.componentDidUpdate) {
      return this.props.componentDidUpdate.apply(this, arguments)
    }
  }

  subtitleDelimiter() {
    const { media } = this.props
    if(media.series && media.speaker) {
      return '|'
    }
  }


  renderFooter() {
    const downloadingDisabled = this.props.settings.downloading_disabled
    const showDownloadDropdown = shouldShowDownloadDropdown(downloadingDisabled, this.props.media, this.props.attachments)

    return(
      <footer className="media-footer">
        <div className="button-container">
          { this.props.isLoading ?
            <PlaceholderButton {...this.props} /> :
            showDownloadDropdown && <DownloadDropdown {...this.props} /> }
        </div>

        <div className="button-container">
          { this.props.shouldShowMore() && this.props.renderShowMore() }
        </div>

        <div className='button-container'>
          { this.props.isLoading ? <PlaceholderButton {...this.props} /> : <ShareButton {...this.props} /> }
        </div>
      </footer>
    )
  }

  render() {
    return(
      <div>
        <div className="media-header">
          <header className="text-content media-video-title">{this.props.media.title}</header>
          <div className="media-info">
            <div className="text-content media-speaker">{this.props.media.speaker}</div>
            <div className="text-content media-info-divider">{this.subtitleDelimiter()}</div>
            <div className="text-content media-series">{this.props.media.series}</div>
          </div>
          <div className="media-details">
            <div className="text-content media-date">{
              this.props.media.date &&
                moment(this.props.media.date).format('MMMM D, YYYY')
            }</div>
          </div>
        </div>
        <div className="media-description text-content" ref={this.props.mediaDescriptionRef}>
          <div className="media-description-full-size" ref={this.props.mediaDescriptionFullSizeRef}>
            { renderHTML(this.props.media.description || '')}
          </div>
        </div>
        { this.renderFooter() }
      </div>
    )
  }
}
