import React from 'react'
import ReactDOM from 'react-dom'

function Header({ design }) {
  if (design === 'serene') {
    return (
      <div className="decoration-and-header-container">
        <div className="decorated-container">
          <div className="rectangle"><div className="hatching" /></div>
          <header className="media-archive-header title-text"><p>Media Archive</p></header>
        </div>
      </div>
    )
  }
  return <header className="media-archive-header">Media Archive</header>
}

export default function MediaArchive({ children, design, theme, id }) {
  if (theme !== 1) {
    // In V1 media sections, the “Media Archive” header is adjacent the filters.
    // In V2 media sections, it is a normal header above the filters.
    children = (
      <>
        <Header design={design} />
        {children}
      </>
    )
  }
  if (theme === 2) {
    // In the V2 media player, the Media Archive appears within what looks like
    // a whole separate adjacent section.  In order to create this appearance
    // with less complex CSS, render these elements outside the current
    // container using a React portal.
    return ReactDOM.createPortal(
      children,
      document.querySelector(`#media-archive-${id}`)
    )
  }
  return <>{children}</>
}
