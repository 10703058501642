import React from 'react'
const { Fragment } = React
import ReactDOM from 'react-dom'
import axios from 'axios'
import classNames from 'classnames'
import * as qs from 'query-string'

import sortAttachments from '../utilities/sortAttachments'

import MediaPlayer from '../components/MediaPlayer'
import MediaArchive from '../components/MediaArchive'
import MediaGrid from '../components/MediaGrid'
import MediaFilterSelect from '../components/MediaFilterSelect'
import MediaBody from '../components/MediaBody'
import NoMediaBody from '../components/NoMediaBody'

import audioOnlyPlayer from '../utilities/audioOnlyPlayer'
import firstSource from '../utilities/firstSource'

import querystring from 'querystring'

import { sortBy, reverse, first, filter, findIndex, includes, map, isEmpty, every, keys } from 'lodash'

function applyFilters(collection, filterObject) {
  return filter(collection, (item) => {
    return every(keys(filterObject), (key) => {
      return isEmpty(filterObject[key]) || includes(filterObject[key], item[key])
    })
  })
}

class MediaWidget extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedMedia: { title: '', speaker: '', series: '', description: '', id: '', content_type: '' },
      isLoaded: false,
      media: [],
      sources: [],
      attachments: [],
      filteredMedia: [],
      filterObject: {},
      type: this.props.type
    }

    this.filterMedia = this.filterMedia.bind(this)
    this.selectMedia = this.selectMedia.bind(this)
    this.loadData = this.loadData.bind(this)
  }

  sortMedia(media) {
    if(media.length > 0) {
      media = sortBy(media, 'title')
      media = sortBy(media, 'id')
      media = sortBy(media, 'date')
      media = reverse(media)
    }
    return media
  }

  findMediaBasedOnParams(media) {
    const parsed = qs.parse(location.search)

    if(parsed) {
      const id = Number(parsed.id)
      return first(filter(media, { id: id }))
    }
  }

  findClosestMedia(selectedMedia, media) {
    media = [...media]
    media.push(selectedMedia)
    media = this.sortMedia(media)

    const deletedIndex = findIndex(media, selectedMedia)

    return media[deletedIndex - 1] || media[deletedIndex + 1] || media[0]
  }

  setMediaState(filteredMedia, media, selectedMedia, isLoaded, callback) {
    this.setState({filteredMedia, media, selectedMedia, isLoaded}, () => {
      if(callback) { callback() }
      // this is needed to make sure that parallax works after the media has loaded
      this.fixParallax()
      // indicate that the placeholder can be replaced with the real player
      this.dataLoaded()
    })

  }

  fixParallax() {
    $(window).trigger('resize.px.parallax')
  }

  dataLoaded() {
    const node = ReactDOM.findDOMNode(this)
    const $article = $(node).closest('article.site-section')
    // Indicate if the archive subsection (if any) should have padding.
    $article.toggleClass('lte-one-media', this.state.media.length <= 1)
    // Adding classes to a parent DOM element may change the result of
    // calculations involving DOM code made by child components.  For instance,
    // the visibility of the element may be changed, permitting calculations
    // which may have been impossible before.  Indicate this change to child
    // components so they perform those calculations now.
    this.setState({ isVisible: true })
  }

  handleDataLoad(response) {
    if (response.data.media.length > 0) {
      const media = this.sortMedia(response.data.media)
      const paramMedia = this.findMediaBasedOnParams(media)
      let defaultSelectedMedia = paramMedia ? paramMedia : media[0]

      if (this.state.selectedMedia && this.state.selectedMedia.id) {
        if (includes(map(media, 'id'), this.state.selectedMedia.id)) {
          this.setMediaState(media, media, this.state.selectedMedia, true, () => {
            this.selectMedia(this.state.selectedMedia.id, false)
          })
        } else {
          defaultSelectedMedia = this.findClosestMedia(this.state.selectedMedia, media)

          if (defaultSelectedMedia) {
            this.setMediaState(media, media, defaultSelectedMedia, true, () => {
              this.selectMedia(this.state.selectedMedia.id, false)
            })
          }
        }
      } else {
        this.setMediaState(media, media, defaultSelectedMedia, true, () => {
          this.selectMedia(this.state.selectedMedia.id, false)
        })
      }

    } else {
      this.setMediaState([], [], null, true, null)
    }
  }

  loadData() {
    const isDraft = this.props.draft ? '1' : '0'
    const queries = { draft: isDraft }
    if (this.props.media_id) {
      queries.media_id = this.props.media_id
    }

    const url = `//mediaplayer.${this.props.base_domain}/players/${this.props.id}?` + querystring.stringify(queries)

    axios.get(url).then(this.handleDataLoad.bind(this))
  }

  componentDidMount() {
    window.Clover.MediaWidgetRegistry.add(this)
    this.loadData()

    if(this.props.draft) {
      this.interval = setInterval(this.loadData, 60000)
    }
  }

  componentWillUnmount() {
    window.Clover.MediaWidgetRegistry.remove(this)

    if(this.props.draft) {
      clearInterval(this.interval)
    }
  }

  filterMedia(filterObject) {
    this.setState({
      filteredMedia: applyFilters(this.state.media, filterObject),
      filterObject: filterObject
    })
  }

  selectMedia(id, shouldScroll) {
    // Pls... this method should only ever get
    // one setState or everything will break. Thanks.
    const isDraft = this.props.draft ? '1' : '0'

    axios.get(`//mediaplayer.${this.props.base_domain}/media/${id}/sources?draft=${isDraft}`).then((response) => {
      this.setState({
        selectedMedia: first(filter(this.state.media, { id: id })),
        sources: response.data.sources,
        attachments: sortAttachments(response.data.attachments)
      })
    })

    if(shouldScroll) {
      const node = ReactDOM.findDOMNode(this)
      $(node).trigger('media:scroll')
    }
  }

  selectedMediaContentType() {
    const { selectedMedia } = this.state
    let contentType = ''
    if (selectedMedia && selectedMedia.content_type) {
      contentType = selectedMedia.content_type
    }
    if(includes(contentType, 'audio')) {
      return 'audio'
    } else if(includes(contentType, 'video')) {
      return 'mp4'
    } else {
      return 'audio'
    }
  }

  selectedMediaIsAvailable() {
    const { selectedMedia } = this.state
    if(!selectedMedia) return false
    if(selectedMedia.upload_type === 'clover' && selectedMedia.state === "finished") {
      const mediaType = this.selectedMediaContentType()
      const availableSources = this.state.sources.filter((s) => s.available === true)
      const filteredSources = availableSources.filter((s) => includes(s.label, mediaType) && !includes(s.label, 'm3u8'))
      return filteredSources.length > 0
    } else if(selectedMedia.upload_type === 'vimeo' || selectedMedia.upload_type === 'youtube') {
      return true
    }
  }

  selectedMediaHasError() {
    const { selectedMedia } = this.state
    if(!selectedMedia) return false
    return selectedMedia.state === 'failed' ||
      selectedMedia.upload_status === 'failed'
  }

  selectedMediaIsReady() {
    return this.selectedMediaIsAvailable() && !this.selectedMediaHasError()
  }

  selectedMediaIsProcessing() {
    return this.state.isLoaded && !this.selectedMediaIsAvailable() && !this.selectedMediaHasError()
  }

  renderMediaState() {
    const { selectedMedia } = this.state

    return(
      <Fragment>
        <MediaPlayer media={selectedMedia}
                     contentType={this.selectedMediaContentType()}
                     isLoading={!this.state.isLoaded}
                     hasError={this.selectedMediaHasError()}
                     isReady={this.selectedMediaIsReady()}
                     isProcessing={this.selectedMediaIsProcessing()}
                     settings={this.props.settings}
                     sources={this.state.sources}
                     attachments={this.state.attachments}
                     draft={this.props.draft}
                     baseDomain={this.props.base_domain} />
        <MediaBody settings={this.props.settings}
                   sectionId={this.props.section_id}
                   pageId={this.props.page_id}
                   theme={this.props.theme}
                   media={selectedMedia}
                   attachments={this.state.attachments}
                   visible={this.state.isVisible}
                   isLoading={!this.state.isLoaded} />

      </Fragment>
    )
  }

  renderNoMediaState() {
    return(
      <NoMediaBody theme={this.props.theme} />
    )
  }

  mediaWidgetClasses() {
    const { media, selectedMedia } = this.state
    let contentType = ''
    if (selectedMedia && selectedMedia.content_type) {
      contentType = first(selectedMedia.content_type.split('/'))
    }

    const source = firstSource(contentType, this.state.sources)
    const url = source ? source.url : null
    const type = audioOnlyPlayer(url, this.props.settings && this.props.settings.show_audio_thumbnails) ? 'audio' : 'video'

    return classNames('media-widget', {
      'empty': this.state.isLoaded && isEmpty(media),
      'audio': type === 'audio',
      'video': type === 'video',
      'ready': this.selectedMediaIsReady()
    })
  }

  renderMediaPlayer() {
    const isLoaded = this.state.isLoaded
    const hasMedia = this.state.media.length > 0
    const mediaId = this.state.selectedMedia ? this.state.selectedMedia.id : ''

    return (
      <div className="media-player" data-media-id={mediaId}>
        { isLoaded && !hasMedia && this.renderNoMediaState() }
        { !(isLoaded && !hasMedia) && this.renderMediaState() }
      </div>
    )
  }

  renderMediaArchive() {
    return (
      <MediaArchive
        design={this.props.design}
        theme={this.props.theme}
        id={this.props.id}>
        <MediaFilterSelect
          theme={this.props.theme}
          media={this.state.media}
          filterMedia={this.filterMedia} />
        <MediaGrid
          pageLength={this.props.page_length}
          filterObject={this.state.filterObject}
          filteredMedia={this.state.filteredMedia}
          selectMedia={this.selectMedia}
          draft={this.props.draft}
          design={this.props.design}
          theme={this.props.theme}
          type={this.state.type}
          selectedMedia={this.state.selectedMedia} />
      </MediaArchive>
    )
  }

  render() {
    return (
      <div className={this.mediaWidgetClasses()}>
        { this.renderMediaPlayer() }
        { this.state.media.length > 1 && this.renderMediaArchive() }
      </div>
    )
  }
}

MediaWidget.defaultProps = {
  theme: 1
}

export default MediaWidget
