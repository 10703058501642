// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'media' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Javascript
import 'es6-promise/auto'
import '../lib/ReactOnRails' // Ensure ReactOnRails has our API extensions.
import ReactOnRails from 'react-on-rails'

import { MediaWidget, MediaRegistry } from '../bundles/MediaWidget'

// Expose API similar to how MediaPlayer used to do it.
window.Clover = window.Clover || {}
window.Clover.MediaWidgetRegistry = new MediaRegistry

// Expose the MediaWidget component so Sites can dynamically render it.
ReactOnRails.register({ MediaWidget })
