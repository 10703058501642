import React from 'react'

import V1MediaBody from './themes/v1/MediaBody'
import V2MediaBody from './themes/v2/MediaBody'

export default class MediaBody extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isShown: false
    }

    this.shouldShowMore = this.shouldShowMore.bind(this)
    this.renderShowMore = this.renderShowMore.bind(this)
    this.toggleShow = this.toggleShow.bind(this)
    this.bodyDidUpdate = this.bodyDidUpdate.bind(this)
  }

  measureDescription() {
    if(this.mediaDescription && this.mediaDescriptionFullSize) {
      this.setState({
        fullSize: this.mediaDescriptionFullSize.offsetHeight,
        condensedSize: this.mediaDescription.offsetHeight
      })
    }
  }

  componentDidUpdate(prevProps) {
    // this prevents the toggle from staying open when you navigate to a new
    // media.  The bug was that if you change media, when you go back to
    // the media you toggled open, there would be no show more link but
    // the toggle would be open.
    if(this.props.media && (this.props.media.id !== prevProps.media.id)) {
      if(this.state.isShown) {
        this.toggleShow()
      }
    }
  }

  componentDidMount() {
    this.measureDescription()
  }

  bodyDidUpdate(previousProps) {
    if(previousProps.media &&
       (previousProps.media.id !== this.props.media.id ||
        (!previousProps.visible && this.props.visible))) {
      this.measureDescription()
    }
  }

  toggleShow() {
    if(this.state.isShown) {
      this.mediaDescription.style.maxHeight = ""
    } else {
      this.mediaDescription.style.maxHeight = this.mediaDescriptionFullSize.offsetHeight + 'px'
    }

    this.setState({isShown: !this.state.isShown})
  }

  renderShowMore() {
    if(this.state.isShown) {
      return <button className="show-more" onClick={this.toggleShow}>Show Less</button>
    } else {
      return <button className="show-more" onClick={this.toggleShow}>Show More</button>
    }
  }

  shouldShowMore() {
    if(this.state.fullSize && this.state.condensedSize) {
      return this.state.fullSize > this.state.condensedSize
    } else {
      return false
    }
  }

  themedMediaBodyComponent() {
    switch(this.props.theme) {
      case 1:
        return V1MediaBody
      case 2:
        return V2MediaBody
    }
  }

  render() {
    const ThemedMediaBody = this.themedMediaBodyComponent()

    return(
      <div className="media-player-body">
        <ThemedMediaBody
          {...this.props}
          componentDidUpdate={this.bodyDidUpdate}
          mediaDescriptionFullSizeRef={(div) => { this.mediaDescriptionFullSize = div }}
          mediaDescriptionRef={(div) => { this.mediaDescription = div}}
          renderShowMore={this.renderShowMore}
          shouldShowMore={this.shouldShowMore} />
      </div>
    )
  }

}
