import React from 'react'

import ReactPaginate from 'react-paginate'
import MediaItem from './MediaItem'

import { assign, sortBy, reverse, findIndex } from 'lodash'

export default class MediaGrid extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      currentPage: 0,
      currentMedia: []
    }
    assign(this.state, this.calculateCurrentPageMedia())

    this.handlePageClick = this.handlePageClick.bind(this)
  }

  componentDidUpdate(previousProps) {
    if(previousProps !== this.props) {
      this.updateCurrentPageMedia(true)
    }
  }

  calculateCurrentPageMedia(calculateNewPage) {
    // not sure why this needs to be sorted again?
    let currentPage = this.state.currentPage
    let currentMedia = this.props.filteredMedia
    currentMedia = sortBy(currentMedia, 'title')
    currentMedia = sortBy(currentMedia, 'id')
    currentMedia = sortBy(currentMedia, 'date')
    currentMedia = reverse(currentMedia)

    if(calculateNewPage) {
      const indexOfSelectedMedia = findIndex(currentMedia, (media) => media.id === this.props.selectedMedia.id)
      currentPage = indexOfSelectedMedia > 0 ? Math.floor( indexOfSelectedMedia / this.pageLength()) : 0
    }

    currentMedia = currentMedia.slice(
                    currentPage * this.pageLength(),
                    (currentPage + 1) * this.pageLength()
                  )

    return { currentMedia, currentPage }
  }

  updateCurrentPageMedia(calculateNewPage) {
    this.setState(this.calculateCurrentPageMedia(calculateNewPage))
  }

  handlePageClick(data) {
    let selected = data.selected

    this.setState({currentPage: selected},() => {
      this.updateCurrentPageMedia()
    })
  }

  pageLength() {
    return this.props.pageLength || 6
  }

  render() {
    const pageCount = this.props.filteredMedia.length / this.pageLength()
    const currentMedia = this.state.currentMedia
    const type = this.props.type

    return (
      <div className={`media-${type}-container`}>
        <ul className={`media-${type}`}>
          { currentMedia.map((m) => {
            return (
              <li key={`media-item-${m.id}-${m.player_id}-${m.status}`}>
                <MediaItem
                  media={m}
                  design={this.props.design}
                  selectedMedia={this.props.selectedMedia}
                  selectMedia={this.props.selectMedia}
                />
              </li>
            )
          }) }
        </ul>

        { pageCount > 1 &&

          <ReactPaginate previousLabel={"previous"}
                         nextLabel={"next"}
                         pageCount={pageCount}
                         marginPagesDisplayed={2}
                         pageRangeDisplayed={3}
                         forcePage={this.state.currentPage}
                         onPageChange={this.handlePageClick}
                         containerClassName={"pagination"}
                         subContainerClassName={"pages pagination"}
                         activeClassName={"active"} />
        }
      </div>
    )
  }
}
