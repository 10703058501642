import React from 'react'

import V1NoMediaBody from './themes/v1/NoMediaBody'
import V2NoMediaBody from './themes/v2/NoMediaBody'

export default class NoMediaBody extends React.Component {

  themedNoMediaBodyComponent() {
    return this.props.theme === 2 ? V2NoMediaBody : V1NoMediaBody
  }

  render() {
    const ThemedNoMediaBody = this.themedNoMediaBodyComponent()
    return(
      <div>
        <ThemedNoMediaBody {...this.props} />
      </div>
    )
  }

}
